const rules = {
    data() {
        return {
            ruleRequired: v => !!v || this.$i18n.translate('validation.required', {'attribute': ''}),
            ruleLt: (v, max) => v < max || this.$i18n.translate('validation.lt.numeric', {
                'attribute': '',
                'value': max
            }),
            ruleGt: (v, min) => v > min || this.$i18n.translate('validation.gt.numeric', {
                'attribute': '',
                'value': min
            }),
            ruleCp: v => /^[0-9]{5}$/.test(v) || this.$i18n.translate('validation.cp'),
            ruleEmail: v => /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v)
                || this.$i18n.translate('validation.email', {'attribute': ''}),
        }
    }
};

export default rules;
