<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row>
            <v-col>
                <contents-ad-component type="vertical" :items="ads"></contents-ad-component>
            </v-col>
        </v-row>
        <v-form ref="form" @submit.prevent="subscribe">
            <v-row>
                <v-col>
                    <h3>{{ $t('menu.newsletter') }}</h3>
                    <h4 v-html="$t('menu.newsletter_details')"></h4>
                </v-col>
            </v-row>
            <v-row align="center" class="mb-8">
                <v-col lg="8" cols="12">
                    <v-text-field
                        v-model="email"
                        hide-details
                        :label="$t('fields.email')"
                        :rules="[ruleRequired, ruleEmail]"
                    ></v-text-field>
                </v-col>
                <v-col lg="4" cols="12">
                    <v-btn @click="subscribe" dark large color="xf">{{ $t('actions.send') }}</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="showMostReadPosts">
                <v-col>
                    <p class="text-h4">{{ $t('menu.most_read') }}</p>
                    <contents-thumbnail-posts-component
                        :posts="mostReadPosts"
                        :show-excerpt="false"
                    ></contents-thumbnail-posts-component>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import rules from '../../mixins/rules'
import { mapGetters } from 'vuex'
import blog from '../../mixins/blog'

export default {
    props: {
        ads: {
            type: Array,
            default () {
                return []
            }
        },
        showMostReadPosts: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            email: null,
            mostReadPosts: []
        }
    },
    mixins: [rules, blog],
    methods: {
        subscribe () {
            if (this.$refs.form.validate() && !this.loading) {
                this.$store.dispatch('loading/loading', true)
                this.subscribeMailchimp(this.email)
                this.$refs.form.reset()
                this.alert(this.$i18n.translate('success.subscribed'), 'success')
                this.$store.dispatch('loading/loading', false)
            }
        },
        getDataFromApi () {
            if (this.showMostReadPosts) {
                this.$store.dispatch('loading/loading', true)
                this.getMostReadPosts().then(response => {
                    this.mostReadPosts = response
                }).catch(error => {
                    this.axiosCatch(error)
                }).finally(() => {
                    this.$store.dispatch('loading/loading', false)
                })
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading'
        }),
    },
    mounted () {
        this.getDataFromApi()
    },
}
</script>

<style scoped>

</style>
