<template>
    <main-layout
        :header="header"
        :footer="footer"
        :pharmacy="pharmacy"
        :absolute="absolute"
        :gotop="gotop"
        :links="linksComputed"
    >
        <slot></slot>
    </main-layout>
</template>

<script>
export default {
    props: {
        header: {
            type: Boolean,
            default: true,
        },
        footer: {
            type: Boolean,
            default: true,
        },
        pharmacy: {
            type: Boolean,
            default: true,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        gotop: {
            type: Boolean,
            default: true,
        },
        links: {
            type: Array,
            default () {
                return []
            }
        }
    },
    computed: {
        linksComputed () {
            if (this.links.length > 0) {
                return this.links
            } else {
                return this.defaultLinks
            }
        },
        defaultLinks () {
            return [
                {
                    id: 'home',
                    translate: 'menu.home',
                    to: { 'name': 'pharmacy.home' },
                },
                {
                    id: 'services',
                    translate: 'menu.services',
                    to: { 'name': 'pharmacy.services' },
                },
                {
                    id: 'map',
                    translate: 'menu.map',
                    to: { 'name': 'pharmacy.map' },
                },
                {
                    id: 'contact',
                    translate: 'menu.contact',
                    to: { 'name': 'pharmacy.contact' },
                },
                // {
                //     id: "announcement",
                //     translate: 'menu.announcement',
                //     to: {"name": 'pharmacy.announcement'},
                // },
            ]
        }
    }
}
</script>

<style scoped>

</style>
