<template>
    <v-container fluid class="px-0 py-12 red lighten-5">
        <v-row>
            <v-col class="text-center">
                <h1>{{ $t('home.pharmacy_services_basics') }}</h1>
            </v-col>
        </v-row>
        <header-divider-component></header-divider-component>
        <v-row class="px-12" justify="center">
            <v-col
                :key="index"
                v-for="(item, index) in basics"
                md="3"
                sm="4"
                lg="2"
            >
                <v-card
                    flat
                    color="transparent"
                >
                    <v-img
                        :src="item.img"
                        contain
                        height="250px"
                    ></v-img>
                    <v-card-text v-if="details" class="text-center">
                        <p>{{ item.content }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <h1>{{ $t('home.pharmacy_services_advanceds') }}</h1>
            </v-col>
        </v-row>
        <header-divider-component></header-divider-component>
        <v-row class="px-12" justify="center">
            <v-col
                :key="index"
                v-for="(item, index) in advanceds"
                md="3"
                sm="4"
                class="d-flex"
            >
                <v-card
                    flat
                    color="transparent"
                >
                    <v-img
                        :src="item.img"
                        contain
                        height="250px"
                    ></v-img>
                    <v-card-text v-if="details" class="text-center">
                        <p>{{ item.content }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!details" class="px-12" justify="center">
            <v-col md="3" class="text-center py-8">
                <v-btn x-large
                       dark
                       outlined
                       block
                       class="xf"
                       @click="services"
                >
                    {{ $t('actions.services_more') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        details: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            basics: [],
            advanceds: [],
        }
    },
    methods: {
        loadImages() {
            this.basics = [
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-5.png`),
                    content: this.$i18n.translate('home.pharmacy_service_1'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-6.png`),
                    content: this.$i18n.translate('home.pharmacy_service_2'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-7.png`),
                    content: this.$i18n.translate('home.pharmacy_service_3'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-8.png`),
                    content: this.$i18n.translate('home.pharmacy_service_4'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-9.png`),
                    content: this.$i18n.translate('home.pharmacy_service_5'),
                }
            ];
            this.advanceds = [
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-10.png`),
                    content: this.$i18n.translate('home.pharmacy_service_6'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-11.png`),
                    content: this.$i18n.translate('home.pharmacy_service_7'),
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-12.png`),
                    content: this.$i18n.translate('home.pharmacy_service_8'),
                }
            ]
        },
        services() {
            this.$router.push({name: 'pharmacy.services'});
        }
    },
    watch: {
        locale() {
            this.loadImages();
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
    },
    mounted() {
        this.loadImages();
    }
}
</script>

<style scoped>

</style>
