<template>
    <customer-layout>
        <v-container fluid class="home pa-0">
            <banner-block :banners="banners"></banner-block>
            <home-customer-commited-block></home-customer-commited-block>
            <home-customer-card-block></home-customer-card-block>
            <home-customer-map-block></home-customer-map-block>
            <home-customer-services-block></home-customer-services-block>
            <home-customer-contact-block></home-customer-contact-block>
        </v-container>
    </customer-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: `${this.$i18n.translate('menu.home')} ${this.$i18n.translate('menu.customer').toLowerCase()}`
        }
    },
    data() {
        return {
            sliders: {
                ca: [],
                es: [],
            }
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
        banners() {
            let images = [];
            this.sliders[this.locale].map(i => {
                images.push({ src: require(`@/assets/img/sliders/customer/${ this.locale }/${ i }`) });
            });
            return images;
        },
    },
    created() {
        require.context('@/assets/img/sliders/customer/', true, /(.*)(gif|jpg|jpeg|tiff|png)$/).keys().forEach(i => {
            let segments = i.replace('./', '').split('/');
            this.sliders[segments[0]].push(segments[1]);
        });
    }
}
</script>

<style scoped>

</style>
