import Vue from 'vue';

const chats = {
    ca: 'https://embed.tawk.to/60f04ba9d6e7610a49ab71d0/1falaf42a',
    es: 'https://embed.tawk.to/60f04ba9d6e7610a49ab71d0/1fanfo9sm',
}

let Tawk_API = {}
// eslint-disable-next-line no-unused-vars
let Tawk_LoadStart = new Date()
let s1 = document.createElement("script")
let s0 = document.getElementsByTagName("script")[0]
s1.async = true
s1.src = chats[Vue.i18n.locale()]
s1.charset = 'UTF-8'
s1.setAttribute('crossorigin', '*')
s0.parentNode.insertBefore(s1, s0)
window.Tawk_API = Tawk_API
