<template>
    <pharmacy-layout>
        <v-container fluid class="pharmacy-home pa-0">
            <services-external-block color="grey"></services-external-block>
            <banner-block :banners="banners"></banner-block>
            <home-pharmacy-strength-block></home-pharmacy-strength-block>
            <home-pharmacy-quality-block></home-pharmacy-quality-block>
            <home-pharmacy-value-block></home-pharmacy-value-block>
            <home-pharmacy-map-block></home-pharmacy-map-block>
            <v-row class="py-4">
                <v-col>&nbsp;</v-col>
            </v-row>
            <home-pharmacy-services-block class="mb-12"></home-pharmacy-services-block>
            <!--<home-pharmacy-dates-block></home-pharmacy-dates-block>-->
            <home-pharmacy-contact-block class="grey lighten-3"></home-pharmacy-contact-block>
        </v-container>
    </pharmacy-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: `${this.$i18n.translate('menu.home')} ${this.$i18n.translate('menu.pharmacy').toLowerCase()}`
        }
    },
    data() {
        return {
            sliders: {
                ca: [],
                es: [],
            }
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
        banners() {
            let images = [];
            this.sliders[this.locale].map(i => {
                images.push({ src: require(`@/assets/img/sliders/pharmacy/${ this.locale }/${ i }`) });
            });
            return images;
        },
    },
    created() {
        require.context('@/assets/img/sliders/pharmacy/', true, /(.*)(gif|jpg|jpeg|tiff|png)$/).keys().forEach(i => {
            let segments = i.replace('./', '').split('/');
            this.sliders[segments[0]].push(segments[1]);
        });
    }
}
</script>

<style scoped>

</style>
