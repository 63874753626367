<template>
    <left-block :title="$t('home.commited')">
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('home.commited_content')"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-img
                        src="@/assets/img/home/quality.png"
                        contain
                        width="400px"
                        class="d-inline-flex"
                    ></v-img>
                </v-col>
            </v-row>
        </template>
        <template v-slot:img>
            <v-img
                src="@/assets/img/home/commited.jpg"
                contain
            ></v-img>
        </template>
    </left-block>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
